import { Face, NearProvider, SolanaProvider } from '@haechi-labs/face-sdk';
import { Blockchain, ConnectedWallet, Network } from '@haechi-labs/face-types';
import { isEthlikeBlockchain, networkToBlockchain } from '@haechi-labs/shared';
import * as solanaSplToken from '@solana/spl-token';
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  createAssociatedTokenAccountInstruction,
  getAccount,
  getAssociatedTokenAddress,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import * as solanaWeb3 from '@solana/web3.js';
import { BN } from 'bn.js';
import { ethers, providers, utils } from 'ethers';
import { poll } from 'ethers/lib/utils';
import * as nearAPI from 'near-api-js';

import { getSolanaRichWallet } from '../config/faucetAccount';
import { config as nearConfig } from '../config/near';
import { SentTransaction } from './types';
import { calcNearTgas, getProvider } from './utils';

// type Web3Provider = providers.Web3Provider;

export const contractAddressMap = {
  [Network.ETHEREUM]: '',
  [Network.SEPOLIA]: '0xAC23B5e3EFc197d24CDc969dA9248cc50f28bF8D',
  [Network.POLYGON]: '',
  [Network.AMOY]: '0xe63c2f4bdd0df2b18b0a4e0210d4b1e95a23dff9',
  [Network.BNB_SMART_CHAIN]: '',
  [Network.BNB_SMART_CHAIN_TESTNET]: '0x33d24CdD4a858BC2965568717C7D11eC38650c56',
  [Network.TEZOS]: '',
  [Network.GHOSTNET]: '',
  [Network.KLAYTN]: '',
  [Network.BAOBAB]: '0xfe72540387e1F9aeFAC07D230dAE1865ad2E733c',
  [Network.SOLANA]: 'TODO',
  [Network.SOLANA_DEVNET]: '4TgNWnJcLbCGx1hVMCgFUsWjhuJQuxEMmh8vVapLCKVY',
  [Network.BORA]: '0x5f07F73c6b3B0F02AB5821e7c1a2E3BcF6A78Bc6',
  [Network.BORA_TESTNET]: '0x10791D8c364DC71928e4F1484a5a7344568d6365',
  [Network.NEAR]: 'TODO',
  [Network.NEAR_TESTNET]: 'facewallet.testnet',
  [Network.APTOS]: '0x3c5737e16dad29cc27a159f42bb1302ce5759e78988b3284b3529be2e6247481',
  [Network.APTOS_TESTNET]: '0x3c5737e16dad29cc27a159f42bb1302ce5759e78988b3284b3529be2e6247481',
  [Network.MEVERSE]: 'TODO',
  [Network.MEVERSE_TESTNET]: '0xe6e54bA1d4E38BDDD635231b59F0a4E237596c46',
  [Network.PSM]: 'TODO',
  [Network.PSM_TESTNET]: '0xC3bee40174BF664E2a4ee884b8D631bA5120Ef28',
  [Network.PSM_TESTNET_TEST]: '0xC3bee40174BF664E2a4ee884b8D631bA5120Ef28',
  [Network.PSM_TESTNET_DEV]: '0xC3bee40174BF664E2a4ee884b8D631bA5120Ef28',
  [Network.HOME_VERSE]: 'TODO',
  [Network.HOME_VERSE_TESTNET]: 'TODO',
  [Network.YOOLDO_VERSE]: '0xf4Fd699E9EfD60dFbB5a3127fdD05e54342E7f60',
  [Network.YOOLDO_VERSE_TESTNET]: '0x5DF6C08Cb3D75C43c7cf74D0c056cAe1cB85D51b',
  [Network.SAND_VERSE]: '0x4fD23Df759732Ec64F4d898971efFc34b4c56d78',
  // OASYS HUB 새로운 스마트 컨트랙트를 배포 못함 https://docs.oasys.games/docs/architecture/hub-layer/hub-layer#smart-contract
  [Network.OASYS]: 'TODO',
  [Network.OASYS_TESTNET]: '0x16B23A1EB0e216b803A8280041fF62f6aFd2B78e',
  [Network.MCH_VERSE]: 'TODO',
  [Network.MCH_VERSE_TESTNET]: '0xb5567463c35dE682072A669425d6776B178Be3E4',
  [Network.HEDERA]: 'TODO',
  [Network.HEDERA_TESTNET]: '0xe4caf34c99a40d7E4Aa512Ad80f1D9aeF8ef0d01',
  [Network.DEFI_VERSE]: 'TODO',
  [Network.DEFI_VERSE_TESTNET]: '0x08e8814A1dCA1c90DFc93A91f170c2BdCfE25576',
  [Network.KROMA]: 'TODO',
  [Network.KROMA_SEPOLIA]: 'TODO',
  [Network.LINEA]: 'TODO',
  [Network.LINEA_GOERLI]: '0xC3bee40174BF664E2a4ee884b8D631bA5120Ef28',

  [Network.LITH]: '0x0000000000000000000000000000000000000008',
  // [Network.ASM]: 'TODO',
  [Network.ASM_QA]: '0xf4Fd699E9EfD60dFbB5a3127fdD05e54342E7f60',
  [Network.ASM_TEST]: '0xf4Fd699E9EfD60dFbB5a3127fdD05e54342E7f60',
  [Network.ASM_DEV]: '0xF0C9B4A5abd2A18Aa350Ab410EE3559796cfC453',
  [Network.ASM_STAGE]: '0x905B20B866939D384dCE838CCA511BFCE0E640Bc',
};

export async function sendContractCall({
  face,
  contractAddress,
  amount,
  txData,
  network,
  wallet,
}: {
  face: Face;
  contractAddress: string;
  amount: string;
  txData: string;
  network: Network;
  wallet?: ConnectedWallet | null;
}): Promise<SentTransaction> {
  const blockchain = networkToBlockchain(network);

  if (isEthlikeBlockchain(blockchain)) {
    const provider = new providers.Web3Provider(
      (await wallet?.connector.getProvider()) || face.getEthLikeProvider(),
      'any'
    );
    return await sendEthlikeContractCall({
      provider,
      contractAddress,
      amount,
      txData,
    });
  }

  if (blockchain === Blockchain.SOLANA) {
    const provider = face.solana.getProvider();
    return await sendSolanaContractCall({
      provider,
      contractAddress,
      amount,
      txData,
      network,
    });
  }

  if (blockchain === Blockchain.NEAR) {
    const provider = face.near.getProvider();
    return await sendNearContractCall({
      provider,
      contractAddress,
      amount,
      txData,
      network,
    });
  }

  throw new Error('unknown blockchain');
}

async function sendEthlikeContractCall({
  provider,
  contractAddress,
  amount,
  txData,
}: {
  provider: ethers.providers.Web3Provider;
  contractAddress: string;
  amount: string;
  txData: string;
}): Promise<SentTransaction> {
  const signer = await provider.getSigner();
  const transactionResponse = await signer.sendTransaction({
    to: contractAddress,
    value: utils.parseUnits(amount),
    data: txData,
  });

  return {
    hash: transactionResponse.hash,
    wait: async () => {
      const receipt = await transactionResponse.wait();
      return {
        status: receipt.status === 1,
        internal: receipt,
      };
    },
    internal: transactionResponse,
  };
}

async function sendSolanaContractCall({
  provider: solanaProvider,
  contractAddress,
  network,
}: {
  provider: SolanaProvider;
  contractAddress: string;
  amount: string;
  txData: string;
  network: Network;
}): Promise<SentTransaction> {
  const connection = new solanaWeb3.Connection(getProvider(network), 'confirmed');
  const recentBlockhash = await connection.getLatestBlockhash('finalized');
  const sender = (await solanaProvider.getPublicKeys())[0];

  console.log('get or carete from account');
  const fromTokenAccount = await faceGetOrCreateAssociatedTokenAddress(
    solanaProvider,
    connection,
    sender,
    new solanaWeb3.PublicKey(contractAddress),
    sender
  );

  const to = getSolanaRichWallet().publicKey;
  const toTokenAccount = await faceGetOrCreateAssociatedTokenAddress(
    solanaProvider,
    connection,
    sender,
    new solanaWeb3.PublicKey(contractAddress),
    to
  );

  const transaction = new solanaWeb3.Transaction().add(
    solanaSplToken.createTransferInstruction(fromTokenAccount, toTokenAccount, sender, 10000)
  );
  transaction.recentBlockhash = recentBlockhash.blockhash;
  transaction.lastValidBlockHeight = recentBlockhash.lastValidBlockHeight;
  transaction.feePayer = sender;

  const result = await solanaProvider.signAndSendTransaction(transaction);

  return {
    hash: result,
    wait: async () => {
      await connection.confirmTransaction({
        signature: result,
        ...recentBlockhash,
      });
      const confirmedTx = await connection.getTransaction(result, {
        maxSupportedTransactionVersion: 0,
      });
      console.log('confirmedTx', confirmedTx);
      return {
        status: confirmedTx?.meta?.err == null,
        internal: confirmedTx,
      };
    },
  };
}

/**
 * solana spl-token 패키지의 getOrCreateAssociatedTokenAccount 함수를 참고했습니다.
 */
async function faceGetOrCreateAssociatedTokenAddress(
  provider: SolanaProvider,
  connection: solanaWeb3.Connection,
  payer: solanaWeb3.PublicKey,
  mint: solanaWeb3.PublicKey,
  owner: solanaWeb3.PublicKey
) {
  const associatedToken = await getAssociatedTokenAddress(
    mint,
    owner,
    false,
    TOKEN_PROGRAM_ID,
    ASSOCIATED_TOKEN_PROGRAM_ID
  );

  try {
    await getAccount(connection, associatedToken);
  } catch (error: unknown) {
    const recentBlockhash = await connection.getLatestBlockhash('finalized');
    const transaction = new solanaWeb3.Transaction().add(
      createAssociatedTokenAccountInstruction(payer, associatedToken, owner, mint)
    );
    transaction.recentBlockhash = recentBlockhash.blockhash;
    transaction.lastValidBlockHeight = recentBlockhash.lastValidBlockHeight;
    transaction.feePayer = payer;
    console.log('create associated token account', associatedToken);
    const txResult = await provider.signAndSendTransaction(transaction);
    console.log('associated token account created', associatedToken, txResult);
  }
  return associatedToken;
}

async function sendNearContractCall({
  provider: nearProvider,
  contractAddress,
  amount,
  txData,
  network,
}: {
  provider: NearProvider;
  contractAddress: string;
  amount: string;
  txData: string;
  network: Network;
}): Promise<SentTransaction> {
  const publicKey = (await nearProvider.getPublicKeys())[0];

  const senderAddress = ethers.utils.hexlify(publicKey.data).slice(2);

  const provider = new nearAPI.providers.JsonRpcProvider({ url: getProvider(network) });
  const args = JSON.parse(txData);

  const accessKey = await provider.query<{
    block_height: number;
    block_hash: string;
    nonce: number;
  }>(`access_key/${senderAddress}/${publicKey.toString()}`, '');

  const nonce = accessKey.nonce + 1;

  const actions = [
    nearAPI.transactions.functionCall('ft_transfer', args, calcNearTgas(6), new BN(amount, 10)),
  ];

  const near = await nearAPI.connect(nearConfig(network));
  const status = await near.connection.provider.status();
  const blockHash = status.sync_info.latest_block_hash;
  const serializedBlockHash = nearAPI.utils.serialize.base_decode(blockHash);

  const tx = nearAPI.transactions.createTransaction(
    senderAddress,
    publicKey,
    contractAddress,
    nonce,
    actions,
    serializedBlockHash
  );

  const result = await nearProvider.signAndSendTransaction(tx);

  return {
    hash: result,
    wait: async () => {
      return await poll(async (): Promise<any> => {
        try {
          const receipt = await provider.txStatus(result, senderAddress);
          return {
            status: Object.keys(receipt!.status as any).includes('SuccessValue'),
            internal: receipt,
          };
        } catch (e) {
          console.log('near polling', e);
          return undefined;
        }
      });
    },
  };
}
