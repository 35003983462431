import { Network } from '@haechi-labs/face-sdk';
import { networkToBlockchain } from '@haechi-labs/shared';
import { BN } from 'bn.js';
import { ethers, providers, utils } from 'ethers';
import { poll } from 'ethers/lib/utils';
import * as nearAPI from 'near-api-js';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { config as nearConfig } from '../../config/near';
import { ERC20_ABI } from '../../libs/abi';
import { createLargeDecimalFT } from '../../libs/types';
import { calcNearTgas, getExplorerUrl, getProvider, makeErc20Data } from '../../libs/utils';
import { accountAtom, faceAtom, networkAtom, walletAtom } from '../../store';
import Box from '../common/Box';
import Button from '../common/Button';
import Field from '../common/Field';
import Message from '../common/Message';
import Select from '../common/Select';

export const erc20Decimal18ContractAddressMap = {
  [Network.ETHEREUM]: '0x8A904F0Fb443D62B6A2835483b087aBECF93a137',
  [Network.SEPOLIA]: '0xfCe04dd232006d0da001F6D54Bb5a7fC969dBc08',
  [Network.POLYGON]: '0xfce04dd232006d0da001f6d54bb5a7fc969dbc08',
  [Network.AMOY]: '0x3c77bdf8ff94a879a6b050ff2c5430d41b009a20',
  [Network.BNB_SMART_CHAIN]: '0xab3e0c68e867f1c81a6660960fdfcf53402b33bf',
  [Network.BNB_SMART_CHAIN_TESTNET]: '0x4c253d0f5de4dac61c5355aaa3efe0872dfadfff',
  [Network.TEZOS]: 'NOT SUPPORTED',
  [Network.GHOSTNET]: 'NOT SUPPORTED',
  [Network.KLAYTN]: '0xab3e0c68e867f1c81a6660960fdfcf53402b33bf',
  [Network.BAOBAB]: '0xb5567463c35dE682072A669425d6776B178Be3E4',
  [Network.SOLANA]: 'TODO',
  [Network.SOLANA_DEVNET]: '4TgNWnJcLbCGx1hVMCgFUsWjhuJQuxEMmh8vVapLCKVY',
  [Network.NEAR]: 'TODO',
  [Network.NEAR_TESTNET]: 'facewallet.testnet',
  [Network.BORA]: '0x5de4eB478160972e6E5250Ee6EF74Eb7e73e8867',
  [Network.BORA_TESTNET]: '0xb6FCfC6c65Be58E2f59530CB357bfeA084C43201',
  [Network.APTOS]: 'TODO',
  [Network.APTOS_TESTNET]: 'TODO',
  [Network.MEVERSE]: 'TODO',
  [Network.MEVERSE_TESTNET]: '0x63C1664c1Ee107D762C7ed7517Ca1cD25bc33C0b',
  [Network.PSM]: 'TODO',
  [Network.PSM_TESTNET]: '0xe4caf34c99a40d7E4Aa512Ad80f1D9aeF8ef0d01',
  [Network.PSM_TESTNET_TEST]: '0xe4caf34c99a40d7E4Aa512Ad80f1D9aeF8ef0d01',
  [Network.PSM_TESTNET_DEV]: '0xe4caf34c99a40d7E4Aa512Ad80f1D9aeF8ef0d01',
  [Network.HOME_VERSE]: '0x614acBDC097E4bFa830996505832Dc155A260dc1',
  [Network.HOME_VERSE_TESTNET]: '0x49d1d2C07313e6a88F7710937C3415f43EAf8337',
  [Network.YOOLDO_VERSE]: '0xccb2c886F9e8E96d69B419aF7607ad935284dDc7',
  [Network.YOOLDO_VERSE_TESTNET]: '0xab3E0c68E867F1C81A6660960FdfcF53402b33BF',
  [Network.SAND_VERSE]: '0xccb2c886F9e8E96d69B419aF7607ad935284dDc7',
  // 새로운 스마트 컨트랙트를 배포 못함 https://docs.oasys.games/docs/architecture/hub-layer/hub-layer#smart-contract
  [Network.OASYS]: 'TODO',
  [Network.OASYS_TESTNET]: '0x646ea0705805AE57C3500d6EC46BF982Fa88ed83',
  [Network.MCH_VERSE]: 'TODO',
  [Network.MCH_VERSE_TESTNET]: '0x63C1664c1Ee107D762C7ed7517Ca1cD25bc33C0b',
  [Network.HEDERA]: 'TODO',
  [Network.HEDERA_TESTNET]: '0xf36c894368d968301dac167a031ebdf1f3c55b23',
  [Network.DEFI_VERSE]: 'TODO',
  [Network.DEFI_VERSE_TESTNET]: '0xf4Fd699E9EfD60dFbB5a3127fdD05e54342E7f60',
  [Network.KROMA]: 'TODO',
  [Network.KROMA_SEPOLIA]: '0x697F935C05466c52237e481D97C369F70f8FbaDc',
  [Network.LINEA]: '0xE63c2F4bDD0df2B18b0A4E0210d4b1e95a23dFf9',
  [Network.LINEA_GOERLI]: '0xab3E0c68E867F1C81A6660960FdfcF53402b33BF',
  [Network.LITH]: '0x000000000000000000000000000000000000000F',
  // [Network.ASM]: 'TODO',
  [Network.ASM_QA]: '0x7d2F1bF6a8d198ff17aA6D3e03a3C10E3AdCa71f',
  [Network.ASM_TEST]: '0xccb2c886F9e8E96d69B419aF7607ad935284dDc7',
  [Network.ASM_DEV]: '0x7d2F1bF6a8d198ff17aA6D3e03a3C10E3AdCa71f',
  [Network.ASM_STAGE]: '0xab3E0c68E867F1C81A6660960FdfcF53402b33BF',
};

export const erc20Decimal6ContractAddressMap = {
  [Network.ETHEREUM]: '',
  [Network.SEPOLIA]: '0xb5567463c35dE682072A669425d6776B178Be3E4',
  [Network.POLYGON]: '',
  [Network.AMOY]: '0x0016af92157bfc2c5fcadfa36b8c7ad5d5a634f3',
  [Network.BNB_SMART_CHAIN]: '0xe63c2f4bdd0df2b18b0a4e0210d4b1e95a23dff9',
  [Network.BNB_SMART_CHAIN_TESTNET]: '0x21881fbff62d55b19b5ded57d8c5dc014da04ea2',
  [Network.TEZOS]: 'NOT SUPPORTED',
  [Network.GHOSTNET]: 'NOT SUPPORTED',
  [Network.KLAYTN]: '0xb3484b204c96b366e1004e94bc50fe637322da47',
  [Network.BAOBAB]: '0x4C253D0f5De4dAC61c5355aaA3EFe0872dfaDFfF',
  [Network.SOLANA]: 'TODO',
  [Network.SOLANA_DEVNET]: 'TODO',
  [Network.NEAR]: 'TODO',
  [Network.NEAR_TESTNET]: 'TODO',
  [Network.BORA]: '0x231234a72478F99c3D1eE0f322bcBA259CAC9412',
  [Network.BORA_TESTNET]: '0x231234a72478F99c3D1eE0f322bcBA259CAC9412',
  [Network.APTOS]: 'TODO',
  [Network.APTOS_TESTNET]: 'TODO',
  [Network.MEVERSE]: '0xab3E0c68E867F1C81A6660960FdfcF53402b33BF',
  [Network.MEVERSE_TESTNET]: '0x72c943436A9218C72836e7eC0E241b763869b417',
  [Network.PSM]: 'TODO',
  [Network.PSM_TESTNET]: '0x86B3722B6604C2510e5B5Dc55c8cFB95ae087c9d',
  [Network.PSM_TESTNET_TEST]: '0x86B3722B6604C2510e5B5Dc55c8cFB95ae087c9d',
  [Network.PSM_TESTNET_DEV]: '0x86B3722B6604C2510e5B5Dc55c8cFB95ae087c9d',
  [Network.HOME_VERSE]: '0xAc99E0BB07687A65A0c4EDE872F096a9E1688A40',
  [Network.HOME_VERSE_TESTNET]: '0x4E6BdF2B2c7D3692f7aCaa7b67209976f03e4A05',
  [Network.YOOLDO_VERSE]: '0x7d2F1bF6a8d198ff17aA6D3e03a3C10E3AdCa71f',
  [Network.YOOLDO_VERSE_TESTNET]: '0xA2fAB648F2CFd5ceA88492808214fCE0CCA15b5E',
  [Network.SAND_VERSE]: '0xb1702eFB3E50d7cb02B82b72eFE020FA011921a5',
  // OASYS HUB 새로운 스마트 컨트랙트를 배포 못함 https://docs.oasys.games/docs/architecture/hub-layer/hub-layer#smart-contract
  [Network.OASYS]: 'TODO',
  [Network.OASYS_TESTNET]: '0x5de4eB478160972e6E5250Ee6EF74Eb7e73e8867',
  [Network.MCH_VERSE]: 'TODO',
  [Network.MCH_VERSE_TESTNET]: '0x72c943436A9218C72836e7eC0E241b763869b417',
  [Network.HEDERA]: 'TODO',
  [Network.HEDERA_TESTNET]: '0xe6F667F97ED03C35Ecab0e5Ae9Fe4C55D57FEE73',
  [Network.DEFI_VERSE]: 'TODO',
  [Network.DEFI_VERSE_TESTNET]: '0x4fD23Df759732Ec64F4d898971efFc34b4c56d78',
  [Network.KROMA]: 'TODO',
  [Network.KROMA_SEPOLIA]: '0x04cd9806dD9B03ce5cc493A60e0A5e4Ae3108C4b',
  [Network.LINEA]: 'TODO',
  [Network.LINEA_GOERLI]: '0xA2fAB648F2CFd5ceA88492808214fCE0CCA15b5E',
  [Network.LITH]: '0x000000000000000000000000000000000000000F',
  // [Network.ASM]: 'TODO',
  [Network.ASM_QA]: '0xccb2c886F9e8E96d69B419aF7607ad935284dDc7',
  [Network.ASM_TEST]: '0x7d2F1bF6a8d198ff17aA6D3e03a3C10E3AdCa71f',
  [Network.ASM_DEV]: '0xcd811021316c19891c47ae6a91E74ecC5FEf9295',
  [Network.ASM_STAGE]: '0xA2fAB648F2CFd5ceA88492808214fCE0CCA15b5E',
};

const title = 'ERC20 Transaction';

function TransactionErc20() {
  const face = useRecoilValue(faceAtom);
  const account = useRecoilValue(accountAtom);
  const network = useRecoilValue(networkAtom)!;
  const wallet = useRecoilValue(walletAtom);
  const [txHash, setTxHash] = useState('');
  const [amount, setAmount] = useState('0.001');
  const [contractAddress, setContractAddress] = useState('');
  const [receiverAddress, setReceiverAddress] = useState('');
  const [balance, setBalance] = useState('');
  const [decimal, setDecimal] = useState('18');

  async function sendTransactionCallback() {
    if (!amount) {
      alert('Please enter amount');
      return;
    }
    if (!contractAddress) {
      alert('Please enter contract address');
      return;
    }
    if (!receiverAddress) {
      alert('Please enter receiver address');
      return;
    }
    try {
      if (network == Network.NEAR || network == Network.NEAR_TESTNET) {
        const nearProvider = face!.near.getProvider();
        const publicKey = (await nearProvider.getPublicKeys())[0];

        const senderAddress = ethers.utils.hexlify(publicKey.data).slice(2);

        const provider = new nearAPI.providers.JsonRpcProvider({ url: getProvider(network) });
        const accessKey = await provider
          .query<{
            block_height: number;
            block_hash: string;
            nonce: number;
          }>(`access_key/${senderAddress}/${publicKey.toString()}`, '')
          .catch(() => ({ nonce: 0 }));

        const nonce = accessKey.nonce + 1;
        const actions = [
          nearAPI.transactions.functionCall(
            'ft_transfer',
            {
              receiver_id: receiverAddress,
              amount: createLargeDecimalFT(
                amount,
                networkToBlockchain(network)
              ).toDecimalAmountAsString(),
            },
            calcNearTgas(6),
            new BN('1', 10)
          ),
        ];
        const near = await nearAPI.connect(nearConfig(network));

        const status = await near.connection.provider.status();

        const blockHash = status.sync_info.latest_block_hash;
        const serializedBlockHash = nearAPI.utils.serialize.base_decode(blockHash);

        const tx = nearAPI.transactions.createTransaction(
          senderAddress,
          publicKey,
          contractAddress,
          nonce,
          actions,
          serializedBlockHash
        );
        const result = await nearProvider.signAndSendTransaction(tx);

        setTxHash(result);

        const sentTx = {
          hash: result,
          wait: async () => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return await poll(async (): Promise<any> => {
              try {
                const receipt = await provider.txStatus(result, senderAddress);
                return {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  status: Object.keys(receipt!.status as any).includes('SuccessValue'),
                  internal: receipt,
                };
              } catch (e) {
                return undefined;
              }
            });
          },
        };

        console.group('[Transaction Information]');
        console.log('Transaction response:', sentTx);
        console.log('Explorer Link:', `${getExplorerUrl(network, sentTx.hash)}`);

        const receipt = await sentTx.wait();
        console.log('Transaction receipt', receipt);
        console.groupEnd();
      } else {
        const provider = new providers.Web3Provider(
          wallet ? await wallet.connector.getProvider() : face!.getEthLikeProvider(),
          'any'
        );

        const signer = await provider.getSigner();
        const transactionResponse = await signer.sendTransaction({
          to: contractAddress,
          value: '0x00',
          data: makeErc20Data(
            'transfer',
            receiverAddress,
            utils.parseUnits(amount, Number(decimal))
          ),
        });

        setTxHash(transactionResponse.hash);

        console.group('[Transaction Information]');
        console.log('Transaction response:', transactionResponse);
        console.log('Explorer Link:', `${getExplorerUrl(network!, transactionResponse.hash)}`);

        const receipt = await transactionResponse.wait();
        console.log('Transaction receipt', receipt);
        console.groupEnd();
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    // Set receiver to user account
    if (account.address) {
      setReceiverAddress(account.address);
    }
  }, [account.address]);

  useEffect(() => {
    // Set default contract address
    if (network) {
      setContractAddress(
        decimal === '18'
          ? erc20Decimal18ContractAddressMap[network]
          : erc20Decimal6ContractAddressMap[network]
      );
    }
  }, [decimal, network]);

  async function getBalance() {
    try {
      if (!contractAddress) {
        alert('Please enter contract address');
        return;
      }

      if (network == Network.NEAR || network == Network.NEAR_TESTNET) {
        const provider = face!.near.getProvider();
        const balance = await provider.getBalance(account.address!, contractAddress);
        setBalance(utils.formatUnits(balance, decimal));
      } else {
        const provider = new providers.Web3Provider(face!.getEthLikeProvider(), 'any');
        const contract = new ethers.Contract(contractAddress, ERC20_ABI, provider);
        const balance = await contract.balanceOf(account.address);

        setBalance(utils.formatUnits(balance, decimal));
      }
    } catch (error) {
      console.error(error);
    }
  }

  if (!face) {
    return (
      <Box title={title}>
        <Message type="danger">You must connect to the network first.</Message>
      </Box>
    );
  }
  if (!account.address) {
    return (
      <Box title={title}>
        <Message type="danger">You must log in and get account first.</Message>
      </Box>
    );
  }

  return (
    <Box title={title} id="transferERC20">
      <Field label="Amount">
        <input
          className="input"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          type="number"
        />
      </Field>
      <Field label="Decimal">
        <Select
          items={[
            { name: 'Decimal 18', value: '18' },
            { name: 'Decimal 6', value: '6' },
          ]}
          onSelect={(value) => setDecimal(value)}
          value={decimal}
        />
      </Field>
      <Field label="Contract Address">
        <input
          className="input"
          value={contractAddress}
          onChange={(e) => setContractAddress(e.target.value)}
        />
      </Field>
      <Field label="Receiver Address">
        <input
          className="input"
          value={receiverAddress}
          onChange={(e) => setReceiverAddress(e.target.value)}
        />
      </Field>
      <Button id="transferBtn" onClick={sendTransactionCallback}>
        Transfer {amount} ERC20 token
      </Button>
      {txHash && (
        <>
          <Message type="info">Hash: {txHash}</Message>
          <Message type="info">
            <a
              href={`${getExplorerUrl(network!, txHash)}`}
              rel="noopener noreferrer"
              target="_blank">
              Explorer Link
            </a>
          </Message>
        </>
      )}
      <Button onClick={getBalance}>Get ERC20 token balance</Button>
      {balance && (
        <Message type="info" className="has-text-left">
          Balance: {balance}
        </Message>
      )}
    </Box>
  );
}

export default TransactionErc20;
